import { NbMenuItem } from '@nebular/theme';

export const MENU_ITEMS: NbMenuItem[] = [

  {
    title: 'Dashboard',
    icon: 'nb-home',
    link: '/pages/dashboard',
  },
  {
    title: 'FEATURES',
    group: true,
  },
  {
    title: 'Users',
    icon: 'nb-person',
    link: '/pages/users',
    children: [
      {
        title: 'Add',
        link: '/pages/users/add',
      },
      {
        title: 'View',
        link: '/pages/users/view',
      },
    ],
  },
  {
    title: 'Category',
    icon: 'fa fa-list',
    link: '/pages/category',
    children: [
      {
        title: 'Add',
        link: '/pages/category/add',
      },
      {
        title: 'View',
        link: '/pages/category/view',
      },
    ],
  },
  {
    title: 'Sub-Category',
    icon: 'fa fa-list',
    link: '/pages/sub-category',
    children: [
      {
        title: 'Add',
        link: '/pages/sub-category/add',
      },
      {
        title: 'View',
        link: '/pages/sub-category/view',
      },
    ],
  },
  {
    title: 'Inventory',
    icon: 'fa fa-list',
    link: '/pages/inventory',
    children: [
      {
        title: 'Add',
        link: '/pages/inventory/add',
      },
      {
        title: 'View',
        link: '/pages/inventory/view',
      },
    ],
  },

  {
    title: 'App Users',
    icon: 'fa fa-mobile',
    link: '/pages/appUsers/view',

  },
  {
    title: 'Offers',
    icon: 'fa fa-gift',
    link: '/pages/offers',
    children: [
      {
        title: 'Add',
        link: '/pages/offers/add',
      },
      {
        title: 'View',
        link: '/pages/offers/view',
      },
    ],

  },
  {
    title: 'Template',
    icon: 'fa fa-mobile',
    link: '/pages/template',
    children: [
      {
        title: 'Add',
        link: '/pages/template/add',
      },
      {
        title: 'View',
        link: '/pages/template/view',
      },
    ],

  },
  {
    title: 'Receipt',
    icon: 'fa fa-list',
    link: '/pages/allbills/view',

  },
  {
    title: 'Redeem',
    icon: 'fa fa-tag',
    link: '/pages/promos/view',

  },
  {
    title: 'Review Sub-Admin',
    icon: 'fa fa-gift',
    link: '/pages/icons',
    children: [
      {
        title: 'Add',
        link: '/pages/reviewSubAdmin/add',
      },
      {
        title: 'View',
        link: '/pages/reviewSubAdmin/view',
      },
    ],

  },
  {
    title: 'Icons',
    icon: 'fa fa-gift',
    link: '/pages/icons',
    children: [
      {
        title: 'Add',
        link: '/pages/icons/add',
      },
      {
        title: 'View',
        link: '/pages/icons/view',
      },
    ],

  },
  {
    title: 'Ip Address',
    icon: 'fa fa-gift',
    link: '/pages/ipAddress',
    children: [
      {
        title: 'View',
        link: '/pages/ipAddress/view',
      },
    ],

  },
  {
    title: 'Csv',
    icon: 'fa fa-gift',
    link: '/pages/csv/add',

  },
   {
    title: 'RepairProcess',
    icon: 'fa fa-list',
    link: '/pages/repairProcess',
    children: [
      {
        title: 'Add',
        link: '/pages/repairProcess/add',
      },
      {
        title: 'View',
        link: '/pages/repairProcess/view',
      },
    ],

  },
   {
    title: 'Invoices',
    icon: 'fa fa-gift',
    link: '/pages/invoices',
    children: [
      {
        title: 'Add',
        link: '/pages/invoices/add',
      },
      {
        title: 'View',
        link: '/pages/invoices/view',
      },
    ],

  },
  {
    title: 'Mobile',
    icon: 'fa fa-mobile',
    link: '/pages/csv/view',
    children: [
      {
        title: 'Buy',
        link: '/pages/csv/view',
      },
      {
        title: 'Sell',
        link: '/pages/csv/sellView',
      },
      {
        title: 'Repair',
        link: '/pages/csv/repairView',
      },
    ],
  },
  {
    title: 'Gifts',
    icon: 'fa fa-gift',
    link: '/pages/csv/view',
    children: [
      {
        title: 'add',
        link: '/pages/gifts/add',
      },
      {
        title: 'Redeemd Gifts',
        link: '/pages/gifts/view',
      }
      ,
      {
        title: 'All Gifts',
       link: '/pages/gifts/all',
      }


    ],
  },

  {
    title: 'Short Messages',
    icon: 'fa fa-headphones-alt',
    link: '/pages/shortMessages',
    children: [
      {
        title: 'Add',
        link: '/pages/shortMessages/add',
      },
      {
        title: 'View',
        link: '/pages/shortMessages/view',
      },

    ],
  },
  {
    title: 'Accessory',
    icon: 'fa fa-headphones-alt',
    link: '/pages/accessory',
    children: [
      {
        title: 'Add',
        link: '/pages/accessory/add',
      },
      {
        title: 'View',
        link: '/pages/accessory/view',
      },
    ],
  },
  {
    title: '',
  },
  {
    title: '',
  }

];
